import { delviumLogo } from "@/plugins/common"
import { mapGetters } from "vuex";
import salesIcon from "@/assets/icons/svg/sales-order-main.svg"
import taxIcon from "@/assets/icons/svg/manage-tax-main.svg"
import creditsIcon from "@/assets/icons/svg/credits-pack-main.svg"

import PaintIcon from '@/assets/icons/svg/paint.svg';
import InvoiceIcon from '@/assets/icons/svg/file-invoice.svg';
//import DashboardIcon from '@/assets/icons/svg/dashboard.svg';
//import CreateCaseIcon from '@/assets/icons/svg/create-case.svg';
import {getSaasUrl} from "@shared/light-ray/services";
import SourceIcon from '@/assets/icons/svg/source.svg';

export default {
  name: "app-sidebar",
  components: {},
  props: [],
  data() {
    return {
      navigation: [
        /*{
          link: "/create-case",
          name: "Create case",
          svg: CreateCaseIcon,
        },
        {
          link: "/",
          name: "Dashboard",
          svg: DashboardIcon,
          pathAliase: '/case-dashboard',
        },*/
        {
          link: "/admin",
          name: "Admin",
          icon: "user-shield",
          children: [
            // {
            //   link: '/check-admin',
            //   name: 'check admin',
            //   icon: 'cog'
            // },
            {
              link: "/field-admin",
              name: "Fields",
              icon: "table",
              breadcrumbBranch: ['Admin', 'Field List'],
            },
            {
              link: "/check-admin",
              name: "Checks",
              icon: "check",
              breadcrumbBranch: ['Admin', 'Check List'],
            },
            {
              link: "/packages",
              name: "Packages",
              icon: "database",
              breadcrumbBranch: ['Admin', 'Package List'],
            },
            {
              link: "/clients-list",
              name: "Clients",
              icon: "user-secret",
              breadcrumbBranch: ['Admin', 'Client List'],
            },
            {
              link: "/users",
              name: "Users",
              icon: "user",
              breadcrumbBranch: ['Admin', 'Users'],
            },
            {
              link: "/groups",
              name: "Groups",
              icon: "users-cog",
              breadcrumbBranch: ['Admin', 'Groups'],
            },
            {
              link: "/email-admin",
              name: "emails",
              icon: "envelope-open",
            },
            {
              link: "/form-admin",
              name: "Forms",
              icon: "keyboard",
              breadcrumbBranch: ['Admin', 'Forms'],
            },
            {
              link: "/license",
              name: "license",
              icon: "user",
              breadcrumbBranch: ['Admin', 'License'],
            },
            // {
            //   link: "/profile-view",
            //   name: "profileView",
            //   icon: "user",
            // },
            // {
            //   link: "/clients",
            //   name: "clients",
            //   icon: "user",
            // },
            // {
            //   link: '/pages',
            //   name: 'sections admin',
            //   icon: 'car'
            // },
            {
              link: '/currency-list',
              name: 'Currency',
              icon: 'coins'
            },
            {
              link: '/credit-packs',
              name: 'Credits Pack',
              svg: creditsIcon
            },
            {
              link: '/taxes',
              name: 'Manage Tax',
              svg: taxIcon
            },
            {
              link: '/source',
              name: 'Source',
              svg: SourceIcon
            },
            {
              link: '/sales-orders',
              name: 'Sales Order',
              svg: salesIcon
            },
            {
              link: '/invoice',
              name: 'Invoice ',
              svg: InvoiceIcon,
            },
          ],
        },
        {
          link: "/settings",
          name: "Settings",
          icon: "cog",
          children: [
            {
              link: "/logo-and-theme",
              name: "Logo & Theme",
              svg: PaintIcon,
              breadcrumbBranch: ['Settings', 'Logo & Theme'],
            },
          ],
        },
        /*{
          // added dummy link to remove active state
          link: "/dummy-link",
          name: "Tools",
          icon: "tools",
          iconPrefix: 'fa',
          customLink: true,
          url: "http://saas.neotas.com/"
        }*/
        {
          name: "Manage Tenant",
          icon: "users-cog",
          link: "/manage-tenant",
        },
        {
          name: "Manage Domain",
          icon: "users-cog",
          link: "/manage-domain",
        }
      ],
      delviumLogo,
    };
  },
  computed: {
    ...mapGetters(['getThemeLogo']),
    isNavCollapsed() {
      return this.$store.getters.getNavCollapsed || false;
    },
  },
  methods: {
    isActive(item) {
      const breadcrumb = this.$route.meta?.breadcrumb;
      return this.$route.path === item.link
        || this.$route.matched.some(el => el.path == item.link || el.path === item.pathAliase)
        // check breadcrumbs to determine child routes (in our case, routes of the same branch):
        || (!!breadcrumb && breadcrumb[0].name === item.breadcrumbBranch?.[0] && breadcrumb[1].name === item.breadcrumbBranch?.[1])
    },
    navCollapseToggle() {
      const navCollapsed = this.$store.getters.getNavCollapsed || false
      this.$store.dispatch('setNavCollapsed', !navCollapsed)
    },
    async handleNavClick(nav) {
      if (!this.isActive(nav.link)) {
        if (nav.children && nav.children.length > 0) {
          let expanded = nav.expanded || false;
          this.navigation = this.navigation.map((nve) =>
            nve.link === nav.link ? { ...nav, expanded: !expanded } : nve
          );
        } else {
          if (nav.customLink) {
            const payload = {
              company_id: this.$store.getters.getUser.id,
              user_id: this.$store.getters.getTenantId
            }
            const {data} = await getSaasUrl(payload)
            window.open(data.research_url, "_blank")
          } else  {
            this.$router
              .push({ path: nav.link })
              .catch((e) => console.log("e >> ", e));
          }
        }
      }
    },
    getNavElement(nav) {
      const link = {
        bind: {
          is: 'router-link',
          tag: 'a',
          to: nav.link,
        },
      };
      const submenu = {
        bind: {
          is: 'div',
        },
        on: {
          'click': () => this.handleNavClick(nav),
        }
      };
      
      if (nav.customLink) {
        return submenu
      } else {
        return nav.children && nav.children.length > 0 ? submenu : link;
      }
    },
  },
};
